<i18n>
  {
    "es": {
        "PAYMENT_METHOD": "Método de pago",
        "CHANGE": "Cambiar",
        "ADD_CARD": "Añadir tarjeta",
        "MY_CARDS": "Mis tarjetas",
        "ERROR_SETTING": "Error al seleccionar el método de pago",
        "ERROR": "ERROR",
        "PAYMENT_UPDATED" : "Método de pago seleccionado",
        "ADD_ADDRESS": "Añadir tarjeta",
        "VALID": "Válida",
        "TERMINATED": "Terminada en **",
        "OTHER_CARDS": "Otras tarjetas",
        "ARROW_ICON": "Icono de flecha a la derecha",
        "CHECK_ICON": "Icono de check verde"
    },
    "en": {
      "PAYMENT_METHOD": "Payment method",
      "CHANGE": "Change",
      "ADD_CARD": "Add card",
      "MY_CARDS": "My cards",
      "ERROR_SETTING": "Error selecting payment method",
      "ERROR": "ERROR",
      "PAYMENT_UPDATED": "Payment method selected",
      "ADD_ADDRESS": "Add creditcard",
      "VALID": "Valid",
      "TERMINATED": "Terminated in **",
      "OTHER_CARDS": "Other creditcards",
      "ARROW_ICON": "Right arrow icon",
      "CHECK_ICON": "Green check icon"
    }
  }
</i18n>

<template>
  <div class="w-full mb-10">
    <h2 class="mt-2 flex justify-start text-base sm:text-base md:text-xl">
      {{ t('PAYMENT_METHOD') }}
    </h2>

    <div class="mt-6 w-full">
      <div class="w-full flex items-center gap-x-4 lg:gap-x-10">
        <div v-for="method in sortedPaymentMethods" :key="method.id">
          <button
            @click="selectPaymentMethod(method.id)"
            class="flex justify-center items-center border rounded-lg p-1 ss:p-2 lg:p-4"
            :class="
              method.selected
                ? 'border-sanremo-primaryblue border-2 bg-sanremo-primaryblue/5'
                : 'border'
            "
            :disabled="method.selected || updating"
          >
            <img
              :src="method.img.src"
              class="w-12 h-12 lg:w-14 lg:h-14 mx-4 lg:mx-6"
            />
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="selectedPaymentMethod"
      class="mt-6 flex items-center tracking-wide gap-x-1"
      :class="
        selectedPaymentMethod.method == 'creditcard'
          ? 'justify-between'
          : 'justify-start'
      "
    >
      <p class="text-xs font-semibold text-sanremo-black">
        {{ selectedPaymentMethod.title }}
      </p>
      <span
        v-if="selectedPaymentMethod.cost > 0"
        class="text-xxs text-sanremo-gray mb-0.5"
        >(+{{ selectedPaymentMethod.cost }}€)</span
      >

      <button
        v-if="selectedPaymentMethod.creditcards.length > 1"
        @click="selectOtherCreditCard = true"
        class="text-xxs mb-1 tracking-wide md:tracking-wider underline underline-offset-2"
      >
        {{ t('OTHER_CARDS') }}
      </button>
    </div>

    <div v-if="selectedPaymentMethod.method === 'creditcard'" class="mt-6">
      <div v-if="selectedCreditcard">
        <button
          :key="selectedCreditcard.id"
          @click="emit('updateCreditcard', selectedCreditcard?.id)"
          class="flex justify-between items-center text-sanremo-black border mt-4 rounded h-20 w-full p-4"
          :class="selectedCreditcard?.selected ? 'border-black' : ''"
          :disabled="selectedCreditcard?.selected"
        >
          <div class="w-full flex justify-between items-center gap-x-4">
            <img
              v-if="selectedCreditcard.brand === 'VISA'"
              src="@/assets/svg/icons/icons-visa-border.svg"
              alt="icono de visa"
            />
            <img
              v-if="selectedCreditcard.brand === 'MASTERCARD'"
              src="@/assets/svg/icons/icons-mastercard-border.svg"
              alt="icono de mastercard"
            />
            <div class="flex flex-col items-start">
              <p class="text-xs tracking-wider font-semibold capitalize">
                {{ selectedCreditcard.alias }}
              </p>
              <p class="text-xs ss:text-xs md:text-sm mt-1 tracking-wider">
                {{ t('TERMINATED') }}{{ selectedCreditcard.pan.slice(-4) }}
              </p>
              <p
                class="text-left text-xxs ss:text-xs md:mt-1 w-4/5 tracking-wider leading-5 text-sanremo-gray font-medium"
              >
                {{ t('VALID') }}
                {{ getExpireDate(selectedCreditcard.expire_at) }}
              </p>
            </div>

            <img
              v-if="selectedCreditcard?.selected"
              src="@/assets/svg/icons/icons-check-green.svg"
              :alt="t('ARROW_ICON')"
              class="ml-auto h-6 w-6"
            />
            <img
              v-else
              src="@/assets/svg/icons/black/icons-right-arrow.svg"
              :alt="t('CHECK_ICON')"
              class="ml-auto"
            />
          </div>
        </button>
        <div class="mt-4">
          <div class="w-full flex justify-start">
            <button
              @click="emit('openNewCreditCardModal')"
              class="text-left text-xxs mb-1 tracking-wide md:tracking-wider underline underline-offset-2"
            >
              {{ t('ADD_ADDRESS') }}
            </button>
          </div>
        </div>
      </div>

      <div v-else class="mt-8">
        <div class="w-full flex justify-start">
          <button
            @click="emit('openNewCreditCardModal')"
            class="text-left text-xxs mb-1 tracking-wide md:tracking-wider underline underline-offset-2"
          >
            {{ t('ADD_ADDRESS') }}
          </button>
        </div>
      </div>
    </div>

    <ClientOnly>
      <LazyModalCheckoutOthersCreditcardsList
        :visibility="selectOtherCreditCard"
        :creditcards="nonSelectedCreditcards"
        @close="selectOtherCreditCard = false"
        @selectCreditCard="emit('updateCreditcard', $event)"
      />
    </ClientOnly>

    <ClientOnly>
      <LazyModalCreditcardConfirmDeleteCheckout
        :visibility="confirmDeleteModal"
        :creditcard="creditcard"
        :deleting="deleting"
        @delete="remove(creditcard.id)"
        @close="confirmDeleteModal = false"
      />
    </ClientOnly>

    <ClientOnly>
      <LazyModalCreditcardConfirmDefaultCheckout
        :visibility="confirmDefaultModal"
        :creditcard="creditcard"
        :updating="updating"
        @update="setAsPrincipal(creditcard.id)"
        @close="confirmDefaultModal = false"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
const props = defineProps({
  sessionId: {
    type: String,
    required: true,
  },
  paymentMethod: {
    type: Object,
  },
  paymentMethods: {
    type: Array,
    required: true,
  },
  saving: Boolean,
})

const creditcard = ref(null)
const confirmDeleteModal = ref(false)
const confirmDefaultModal = ref(false)
const selectOtherCreditCard = ref(false)
const deleting = ref(false)
const updating = ref(false)
const { isDesktop } = useDevice()
const { addMessage } = useSnackBar()

const { t } = useI18n({
  useScope: 'local',
})

const { locale } = useI18n()

const emit = defineEmits([
  'updateCreditcard',
  'refresh',
  'openNewCreditCardModal',
])

const selectedPaymentMethod = computed(() => {
  return props.paymentMethods.find((method) => method.selected)
})

const sortedPaymentMethods = computed(() => {
  const methodPriority = {
    creditcard: 1,
    Bizum: 2,
    'Transferencia Bancaria': 3,
    'Contra Reembolso': 4,
    redsys: 5,
    PayPal: 6,
  }

  return [...props.paymentMethods]
    .filter((method) => import.meta.dev || !method.debug)
    .sort((a, b) => {
      return (methodPriority[a.method] || 99) - (methodPriority[b.method] || 99)
    })
})

const selectedCreditcard = computed(() => {
  const selected = selectedPaymentMethod?.value.creditcards.find(
    (card) => card.selected
  )

  return (
    selected || selectedPaymentMethod?.value.creditcards.find(Boolean) || null
  )
})

const nonSelectedCreditcards = computed(() => {
  return (
    selectedPaymentMethod?.value.creditcards.filter((card) => !card.selected) ||
    []
  )
})

const hasSavedCards = computed(() => {
  return (
    props.paymentMethod?.creditcards &&
    props.paymentMethod.creditcards.length > 0
  )
})

const activeTab = ref(hasSavedCards.value ? 'myCards' : 'addCard')

// TODO USEASYNCDATA OUTSIDE OF THE FUNCTION

const selectPaymentMethod = async (methodId) => {
  updating.value = true

  const { data, error } = await useAsyncData('selectPaymentMethod', () =>
    $fetch('/api/checkout/payments/select', {
      method: 'POST',
      body: {
        sessionId: props.sessionId,
        method: methodId,
      },
    })
  )

  if (error.value) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('ERROR_SETTING'),
    })
  }
  updating.value = false
  emit('refresh')
}

const confirmRemove = (id) => {
  const creditcardInCreditcards = props.paymentMethod?.creditcards?.find(
    (creditcard) => creditcard.id == id
  )

  creditcard.value = creditcardInCreditcards
  confirmDeleteModal.value = true
}

const remove = async (id) => {
  deleting.value = true
  const { error } = await useAsyncData(`deleteCard${id}`, () =>
    $fetch(`/api/account/creditcards/${id}/delete`)
  )
  if (error.value) {
    handleError(error.value)
    deleting.value = false
  } else {
    addMessage({
      type: 'success',
      result: 'OK',
      text: 'Tarjeta eliminada',
    })
    confirmDeleteModal.value = false
    deleting.value = false
    emit('refresh')
  }
}

const handleError = (error) => {
  console.debug(error)
  addMessage({
    type: 'error',
    result: 'ERROR',
    text: t('ERROR'),
  })
}

const confirmDefault = (id) => {
  const creditcardInCreditcards = props.paymentMethod?.creditcards?.find(
    (creditcard) => creditcard.id == id
  )
  creditcard.value = creditcardInCreditcards
  confirmDefaultModal.value = true
}

const setAsPrincipal = async (id) => {
  updating.value = true
  const { error } = await useAsyncData(
    `updatedefaultcreditCard`,
    () =>
      $fetch(`/api/account/creditcards/${id}/default`, {
        method: 'GET',
      }),
    {}
  )
  if (error.value) {
    handleError(error.value)
    updating.value = false
  } else {
    addMessage({
      type: 'success',
      result: 'OK',
      text: 'Tarjeta principal actualizada',
    })
    confirmDefaultModal.value = false
    updating.value = false
    emit('refresh')
  }
}

const formSubmit = async (data) => {
  const { alias: ccAlias, ccholder: holder, token: ccToken } = data

  emit('submitForm', { ccAlias, holder, ccToken })
}

const getExpireDate = (stringDate) => {
  const date = new Date(stringDate)
  const month = date.getMonth() + 1
  let year = date.getFullYear()
  year = year.toString().substr(-2)
  if (month < 10) return `0${month}/${year}`
  return `${month}/${year}`
}
</script>
