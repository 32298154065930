<i18n>
  {
    "es": {
      "PHONE": "Teléfono:",
      "SCHEDULE": "Horario:",
      "SELECT": "Seleccionar",
      "SELECTED": "Seleccionado",
      "MONDAY": "Lunes",
      "TUESDAY": "Martes",
      "WEDNESDAY": "Miércoles",
      "THURSDAY": "Jueves",
      "FRIDAY": "Viernes",
      "SATURDAY": "Sábado",
      "SUNDAY": "Domingo",
      "CLOSED": "Cerrado",
      "DAY": "Día",
      "TIME": "Hora",
      "CANCEL": "Cancelar",
      "SAVING": "Guardando...",
      "STORE_PHOTO": "Foto de la tienda",
      "COMPANY_LOGO": "Logotipo de la empresa",
      "LOADING_ICON": "Icono de carga"
    },
    "en": {
      "PHONE": "Phone:",
      "SCHEDULE": "Hours:",
      "SELECT": "Select",
      "SELECTED": "Selected",
      "MONDAY": "Monday",
      "TUESDAY": "Tuesday",
      "WEDNESDAY": "Wednesday",
      "THURSDAY": "Thursday",
      "FRIDAY": "Friday",
      "SATURDAY": "Saturday",
      "SUNDAY": "Sunday",
      "CLOSED": "Closed",
      "DAY": "Day",
      "TIME": "Time",
      "CANCEL": "Cancel",
      "SAVING": "Saving...",
      "STORE_PHOTO": "Store photo",
      "COMPANY_LOGO": "Company logo",
      "LOADING_ICON": "Loading icon"
    }
  }
</i18n>

<template>
  <ModalCourtain @close="cancel" :visibility="visibility" :zIndex="1000">
    <template v-slot:content>
      <div class="py-9 md:py-0 min-h-[450px] md:min-h-0">
        <div class="flex justify-start items-center gap-x-4">
          <img src="@/assets/img/dpd-store.jpg" :alt="t('STORE_PHOTO')" />
          <div class="flex flex-col">
            <img
              :src="selectedPickUpPoint.icon"
              :alt="t('COMPANY_LOGO')"
              class="h-10 w-16"
            />
            <p
              class="text-xs uppercase text-sanremo-black font-semibold tracking-wider"
            >
              {{ selectedPickUpPoint.name }}
            </p>
            <p class="text-sanremo-gray text-xs tracking-wider">
              {{
                selectedPickUpPoint?.address?.address_street ||
                selectedPickUpPoint.address_street
              }}
            </p>
            <p class="text-xs text-sanremo-black tracking-wide">
              {{ selectedPickUpPoint.distance }}
            </p>
          </div>
        </div>

        <table
          class="container mx-auto mt-5 min-w-full bg-white border border-neutral-light-grey-1 border-collapse text-sm text-neutral-black-3"
        >
          <thead>
            <tr>
              <th
                class="py-3 px-6 border-b border-r text-start tracking-wide text-black font-bold"
              >
                {{ t('DAY') }}
              </th>
              <th
                class="py-3 px-6 border-b text-start tracking-wide text-black font-bold"
              >
                {{ t('TIME') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="time in selectedPickUpPoint?.info?.timetable ||
              selectedPickUpPoint.timetable"
              :key="time.day"
            >
              <td class="py-3 px-6 border-b border-r text-start">
                {{ t(time.day) }}
              </td>
              <td
                class="py-3 px-6 border-b text-start"
                v-if="!time.fullDay && (time.morning || time.afternoon)"
              >
                {{ time.morning }} | {{ time.afternoon }}
              </td>
              <td
                class="py-3 px-6 border-b text-start"
                v-else-if="time.fullDay"
              >
                {{ time.fullDay }}
              </td>
              <td class="py-3 px-6 border-b text-start" v-else>
                {{ t('CLOSED') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-slot:footer>
      <div
        v-if="showButtons"
        class="sticky bottom-0 w-full bg-white pb-2 px-4 pt-2 shadow-inner mdplus:rounded-lg z-50"
      >
        <div class="flex flex-col space-y-5 items-center justify-between">
          <button
            @click="confirmLocation"
            :aria-label="t('SELECT')"
            :disabled="selectedPickUpPoint.selected || saving"
            type="submit"
            class="uppercase w-full text-white animation-get-light rounded h-12 font-bold text-sm transition-all duration-200 flex items-center justify-center"
            :class="{
              'bg-others-black bg-opacity-90':
                !selectedPickUpPoint.selected && !saving,
              'bg-gray-400': selectedPickUpPoint.selected || saving,
            }"
          >
            <img
              v-if="saving"
              src="@/assets/svg/icons/icons-loading.svg"
              :alt="t('LOADING_ICON')"
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            />
            <span v-if="saving">{{ t('SAVING') }}</span>
            <span v-else-if="selectedPickUpPoint.selected">{{
              t('SELECTED')
            }}</span>
            <span v-else>{{ t('SELECT') }}</span>
          </button>
          <button
            class="hidden md:block w-fit text-sm text-center"
            @click="cancel"
          >
            {{ t('CANCEL') }}
          </button>
        </div>
      </div>
    </template>
  </ModalCourtain>
</template>

<script setup>
const props = defineProps({
  selectedPickUpPoint: Object,
  visibility: Boolean,
  showButtons: Boolean,
  saving: Boolean,
})

const { t } = useI18n({
  useScope: 'local',
})

const emit = defineEmits(['close', 'select'])

const cancel = () => {
  emit('close')
}

const confirmLocation = () => {
  emit('select', props.selectedPickUpPoint.id)
}
</script>
