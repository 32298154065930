<i18n>
  {
        "es": {
        "TOTAL": "Total",
        "IVA_INCLUDED": "IVA incluido",
        "MAKE_PURCHASE": "Realizar compra",
        "MAKING_PURCHASE": "Autorizando...",
        "METHOD_NOT_SUPPORTED": "Método de Pago no Soportado",
        "CUSTOMER_ABORT": 'Proceso Cancelado',
        "INVALID_DATA": 'Datos no validos',
        "INVALID_ADDRESS": 'Dirección no válida',
        "INVALID_CITY": 'Ciudad no válida',
        "INVALID_NAME": 'Nombre no válido',
        "INVALID_POSTCODE": 'Código Postal no válida',
        "INVALID_LASTNAME": 'Apellido no válido',
        "INVALID_EMAIL": 'Correo Electrónico no válido',
        "TRANSACTION_DECLINED": 'Transacción Rechazada',
        "UNKNOWN_ERROR": 'Upss.. Ha ocurrido un error inesperado. Vuelve a intentarlo.',
        "SOMETHING_GET_WRONG": 'Upss.. Algo ha ido mal. Vuelve a intentarlo.',
    },
    "en": {
      "TOTAL": "Total",
      "IVA_INCLUDED": "VAT included",
      "MAKE_PURCHASE": "Make purchase",
    }
}
</i18n>
<template>
  <div
    class="sticky bottom-0 w-full bg-white pb-4 px-4 pt-2 shadow-inner mdplus:rounded-lg z-50"
  >
    <div class="flex justify-between py-2 mb-4 text-base md:py-1">
      <p class="font-bold tracking-wider">
        {{ t('TOTAL') }}
        <span class="text-others-grey3 font-thin tracking-wider">
          {{ t('IVA_INCLUDED') }}
        </span>
      </p>
      <p class="font-semibold tracking-wider">
        {{ formatPrice(total) }} {{ currency.symbol }}
      </p>
    </div>
    <button
      v-if="mode === 'normal'"
      :disabled="!enableButton"
      @click="emit('confirm')"
      class="h-14 w-full tracking-wider rounded uppercase bg-others-black font-bold text-sm text-white transition-all duration-200 flex items-center justify-center gap-2"
      :style="'background-size: 200% 100%;'"
      :class="
        !enableButton
          ? 'opacity-50 cursor-not-allowed'
          : 'cursor-pointer hover:animate-[slide-in_0.83s] hover:bg-[200%_100%] hover:bg-[linear-gradient(270deg,#212121_50%,#000_0)] animate-[get-light_0.25s]'
      "
    >
      <img
        v-if="confirming"
        src="@/assets/svg/icons/icons-loading.svg"
        class="animate-spin h-6 w-6"
      />
      <span class="text-sm tracking-wider">{{
        confirming ? t('MAKING_PURCHASE') : t('MAKE_PURCHASE')
      }}</span>
    </button>
    <div
      v-else-if="mode === 'paypal'"
      id="paypal-buttons-container"
      class="flex gap-2"
      :class="
        !enableButton
          ? 'opacity-50 cursor-not-allowed pointer-events-none'
          : 'cursor-pointer'
      "
    ></div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { formatPrice } from '@/utils/helpers'

import { usePayPal } from '@/composables/payments/paypal'

const { t } = useI18n()

const props = defineProps({
  total: {
    type: Number,
    required: true,
  },
  currency: {
    type: Object,
    required: true,
  },
  enableButton: {
    type: Boolean,
    required: false,
  },
  status: {
    type: String,
    required: true,
  },
  mode: {
    type: String,
    required: false,
    default: 'normal',
    validator: (value) => ['normal', 'paypal'].includes(value),
  },
})

const emit = defineEmits(['confirm', 'completed', 'error'])

const confirming = computed(() => props.status === 'CONFIRMING')

const paypalActions = ref({})

const enableButton = toRef(props, 'enableButton')

// Usar watchEffect para reaccionar a cambios en paypalActions y enableButton
watchEffect(() => {
  if (!window?.paypal) return
  const FUNDING_SOURCES = [
    window.paypal.FUNDING.PAYPAL,
    window.paypal.FUNDING.PAYLATER,
  ]
  FUNDING_SOURCES.forEach((fundingSource) => {
    const actions = paypalActions.value[fundingSource]
    if (actions) {
      if (enableButton.value) {
        actions.enable()
      } else {
        actions.disable()
      }
    }
  })
})

const renderPayPalButtons = () => {
  if (props.mode === 'paypal') {
    const { onLoaded } = usePayPal()
    onLoaded(() => {
      const FUNDING_SOURCES = [
        window.paypal.FUNDING.PAYPAL,
        window.paypal.FUNDING.PAYLATER,
      ]
      FUNDING_SOURCES.forEach(function (fundingSource) {
        var button = window.paypal.Buttons({
          fundingSource: fundingSource,
          style: {
            color: 'black',
            tagline: false,
            height: 50,
          },
          onInit(data, actions) {
            paypalActions.value[fundingSource] = actions
            if (!enableButton.value) {
              actions.disable()
            }
          },
          onClick(data, actions) {
            console.log('Aqui va el click ' + enableButton.value)
          },
          createOrder: async function () {
            try {
              const response = await $fetch(
                '/api/checkout/payments/paypal/create',
                {
                  method: 'POST',
                  body: {
                    amount: props.total,
                    currency: props.currency.code,
                  },
                }
              )

              return response.id // Asumiendo que la respuesta contiene un ID de orden
            } catch (error) {
              console.error('Error al crear la orden de PayPal:', error)
              emit('error', error)
            }
          },
          onApprove: async function (data, actions) {
            try {
              const response = await $fetch(
                '/api/checkout/payments/paypal/capture',
                {
                  method: 'POST',
                  body: {
                    id: data.orderID,
                  },
                }
              )

              emit('completed', response)
            } catch (error) {
              console.error('Error al crear la orden de PayPal:', error)
              emit('error', error)
            }
          },
          onCancel: function () {
            console.log('Transaccion cancelada...')
            //onErrorCallback(createError({ message: 'CUSTOMER_ABORT' }))
          },
          onError: function (err) {
            console.debug('paypal onError')
            console.debug(err)
            emit('error', error)
          },
          // Add more PayPal button configuration here
        })

        if (button.isEligible()) {
          button.render('#paypal-buttons-container')
        }
      })
    })
  }
}

watch(
  () => props.mode,
  (newMode) => {
    if (newMode === 'paypal') {
      renderPayPalButtons()
    }
  }
)

onMounted(() => {
  renderPayPalButtons()
})
</script>
