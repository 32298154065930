<i18n>
  {
    "es": {
        "EDIT": "Editar",
        "REMOVE": "Eliminar",
    },
    "en": {
      "EDIT": "Edit",
      "REMOVE": "Remove",
    }
}
</i18n>

<template>
  <div class="w-full">
    <div
      class="flex justify-center items-center hover:cursor-pointer gap-x-6 md:gap-x-4 w-11/12 md:w-11/12"
    >
      <div
        class="flex justify-between items-center h-[135px] border-1 rounded w-full pl-4 py-4 pr-1 relative"
        :class="
          address?.selected ? 'border-neutral-black-2' : 'border-neutral-grey-4'
        "
      >
        <div class="flex flex-col items-start text-xs w-full">
          <div class="flex items-center justify-between w-full">
            <p class="tracking-wider">{{ address?.alias }}</p>
            <div v-if="!address?.selected" class="relative">
              <button
                @click.stop="toggleMenu($event)"
                class="focus:outline-none"
              >
                <img
                  src="@/assets/svg/icons/black/icons-options.svg"
                  class="w-6 h-6 transform transition-transform duration-200 ease-in-out hover:scale-110"
                />
              </button>
              <div
                v-if="isMenuOpen"
                class="absolute right-2 mt-2 w-32 bg-white border border-neutral-grey-4 rounded shadow-md z-10"
              >
                <button
                  class="flex items-center w-full px-4 py-2 text-left hover:bg-sanremo-gray/10"
                  @click.stop="editAddress"
                >
                  <img
                    src="@/assets/svg/icons/black/icons-edit-simple.svg"
                    class="w-4 h-4 mr-2"
                  />
                  {{ t('EDIT') }}
                </button>
                <button
                  class="flex items-center w-full px-4 py-2 text-left text-sanremo-black hover:bg-sanremo-gray/10"
                  @click.stop="remove(address.id)"
                  :disabled="address?.selected"
                  :class="address?.selected ? 'cursor-not-allowed' : ''"
                >
                  <img
                    src="@/assets/svg/icons/icons-paperbin.svg"
                    class="w-4 h-4 mr-2"
                  />
                  {{ t('REMOVE') }}
                </button>
              </div>
            </div>
            <div v-else>
              <button @click.stop="editAddress">
                <img
                  src="@/assets/svg/icons/black/icons-edit-simple.svg"
                  class="w-4 h-4 mr-2 transform transition-transform duration-200 ease-in-out hover:scale-110"
                />
              </button>
            </div>
          </div>

          <p
            class="text-neutral-black-3 w-full text-left mt-1 tracking-wider whitespace-normal leading-5"
          >
            {{ address?.address_street }} {{ address?.address_complement }}
          </p>
          <p
            class="text-neutral-black-3 w-full text-left mt-1 tracking-wider whitespace-normal leading-5"
          >
            {{ address?.postcode }} {{ address?.address_city }}
          </p>
          <p
            class="text-neutral-black-3 w-full text-left mt-1 tracking-wider whitespace-normal leading-5"
          >
            {{ address?.address_state }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  addresses: Array,
  address: Object,
  addressIndex: Number,
})

const { t } = useI18n({
  useScope: 'local',
})

const emit = defineEmits(['delete', 'edit'])

const confirmAddAddressModal = ref(false)
const isMenuOpen = ref(false)

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const editAddress = () => {
  emit('edit')
  toggleMenu()
}

const remove = async (id) => {
  emit('delete')
  toggleMenu()
}
</script>
