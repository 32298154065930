<i18n>
  {
    "es": {
      "DELETE": "Eliminar",
      "DELETING": "Eliminando...",
      "CANCEL": "Cancelar",
      "CANCELING": "Cancelando...",
      "SURE": "¿Estás seguro de eliminar este cupón?"
    },
    "en": {
      "DELETE": "Delete",
      "DELETING": "Deleting...",
      "CANCEL": "Cancel",
      "CANCELING": "Canceling...",
      "SURE": "Are you sure you want to delete this coupon?"
    }
  }
</i18n>

<template>
  <ModalCourtain :visibility="visibility" @close="cancel">
    <template v-slot:title>
      <div class="flex flex-col items-center justify-center w-full bg-white">
        <h2 class="px-8 text-xl text-others-black text-center">
          {{ t('SURE') }}
        </h2>
      </div>
    </template>
    <template v-slot:content>
      <div class="mt-2 w-full">
        <div class="flex justify-center items-center mt-8">
          <div
            class="flex justify-start items-center border-1 border-neutral-light-grey-2 rounded h-22 w-full p-4"
          >
            <div class="flex justify-between w-full items-center">
              <div class="flex flex-col">
                <p class="text-sm">{{ coupon?.title }}</p>
                <p
                  class="text-neutral-black-3 text-xs xs:text-sm w-full max-w-[270px] mt-2 tracking-wider leading-5 xs:truncate"
                >
                  {{ coupon?.description }}
                </p>
              </div>
              <div class="text-base font-black">-{{ coupon?.discount }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer
      ><div class="flex flex-col space-y-5 items-center justify-between">
        <button
          @click="confirmDelete"
          :aria-label="t('DELETE')"
          type="submit"
          class="uppercase w-full bg-others-black bg-opacity-90 text-white animation-get-light rounded h-14 font-bold text-sm transition-all duration-200 flex items-center justify-center"
        >
          <img
            v-if="deleting"
            src="@/assets/svg/icons/icons-loading.svg"
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          />

          <span v-if="deleting">{{ t('DELETING') }}</span>
          <span v-else>{{ t('DELETE') }}</span>
        </button>

        <button
          @click="cancel"
          :aria-label="t('CANCEL')"
          class="uppercase w-full bg-white bg-opacity-90 text-black animation-get-light border-1 border-black rounded h-14 font-bold text-sm transition-all duration-200 flex items-center justify-center"
        >
          <span v-if="deleting">{{ t('DELETING') }}</span>
          <span v-else>{{ t('CANCEL') }}</span>
        </button>
      </div></template
    >
  </ModalCourtain>
</template>

<script setup>
const props = defineProps({
  visibility: Boolean,
  coupon: Object,
  deleting: Boolean,
})

const { t } = useI18n({
  useScope: 'local',
})

const emit = defineEmits(['close', 'delete'])

const cancel = () => {
  emit('close')
}

const confirmDelete = () => {
  emit('delete')
}
</script>
