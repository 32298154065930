<i18n>
  {
    "es": {
      "FREE": "Gratis"
    },
    "en": {
      "FREE": "Free"
    }
  }
</i18n>

<template>
  <div class="flex flex-col items-start w-full tracking-wider mt-8">
    <div
      class="mb-6 flex items-center w-full justify-between"
      v-for="total in totals"
      :key="total.type"
    >
      <span v-if="total.type !== 'total'">{{ total.title }}</span>

      <div v-if="total.type === 'shipping' && total.value === 0">
        <div class="space-x-1">
          <span class="text-sm line-through">
            {{ mockedShippingCost }}{{ currency.symbol }}
          </span>
          <span class="uppercase text-green-500 font-medium">
            {{ t('FREE') }}
          </span>
        </div>
      </div>

      <div v-else>
        <span v-if="total.type !== 'total'">
          <span v-if="total.type === 'discount'">-</span
          >{{ formatPrice(total.value) }}{{ currency.symbol }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatPrice } from '@/utils/helpers'

const { t } = useI18n({
  useScope: 'local',
})

const props = defineProps({
  totals: {
    type: Array,
    required: true,
  },
  currency: {
    type: Object,
    required: true,
  },
})

const mockedShippingCost = ref('4,99')
</script>
